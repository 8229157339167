.socialMediaWrapper {
    margin-top: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
}
.socialMediaWrapper > .iconImage {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}