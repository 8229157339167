.aboutCenterWrapper{
    margin-top: 4rem;
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.aboutCenterWrapper > div {
    flex: 0 0 calc(50% - 0.8rem);
}

@media screen and (max-width: 800px) {
    .aboutCenterWrapper {
        flex-direction: column;
    }
}