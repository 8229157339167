.pageWrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.iconInformationWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    gap: 1.5rem;
    
}
.iconInformationContainer {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    
}
.iconInformationContainer > img {
    width: 10rem;
    height: 10rem;
}
.iconInformationContainer > p {

}

.contactUsFormWrapper {
    width: min(35rem, 100%);
    margin: auto;
    
}
.contactUsInput {
    margin-top: 0.8rem;
}

/* Second Try */
.firstBlock {
    display: flex;
    flex-direction: row;
}
.secondBlock {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.phoneInput {
    width: 100% !important;
}

.formErrorText {
    color: red !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ Media Query ~~~~~~~~~~~~~~~~~~~~~~~~~ */
@media screen and (max-width: 700px) {
    .firstBlock {
        flex-direction: column;
    }
    .firstBlock > div {
        height: auto !important;
        min-height: auto !important;
    }
    .firstBlock > div:last-of-type {
        min-height: 350p;
    }
    /* .firstBlock > div:last-of-type >  */
}
@media screen and (max-width: 750px) {
    .iconInformationWrapper {
        flex-direction: column;
    }
    .iconInformationContainer {
        flex-direction: row;
    }
    .iconInformationContainer > img {
        width: 8rem;
        height: 8rem;
    }
}
@media screen and (max-width: 850px) {
    .secondBlock > div {
        flex: 1;
    }
    .secondBlock > div:first-of-type {
        flex: 0 0 100%;
    }
}
@media screen and (max-width: 580px) {
    .secondBlock > div {
        flex: 0 0 100%;
    }
}