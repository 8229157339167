.preHeaderContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 0px;
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~ Media Query ~~~~~~~~~~~~~~~~~~~~~~~~~ */
@media screen and (max-width: 900px) {
  .preHeaderContainer {
    flex-direction: column;
    gap: 0.3rem;
  }
}
