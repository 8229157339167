html, body {
  margin: 0 !important;
  color: #000000 !important;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 1000px) {
  html,
  body {
    font-size: 14px;
  }
}
@media screen and (max-width: 500px) {
  html, body {
    font-size: 12px;
  }
}
@media screen and (max-width: 400px) {
  html,
  body {
    font-size: 10px;
  }
}


/* For window scrollbar */
/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  border: 1px solid #d5d5d5;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 1px #fff;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #ddd;
}