.headerComponentWrapper {
  position: sticky !important;
  top: 0;
  z-index: 11;
  box-shadow: 0px 0px 10px 0px rgba(207, 204, 204, 0.5);
  
}
.headerWrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}
.navLinkTextWrapper {
  justify-content: flex-end;
}
.navLinkTextContainer {
}
.navLinkText {
  font-weight: 600 !important;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ Media Query ~~~~~~~~~~~~~~~~~~~~~~~~~ */
@media screen and (max-width: 1000px) {
  .navLinkTextWrapper {
    order: 3;
    flex: 0 0 100%;
  }
}
