.oasisPeaceWrapper {
    margin-top: 10rem;
}
.oasisPeaceContainer {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
}
.oasisPeaceContainer > .leftSideTextContainer, .oasisPeaceContainer > .rightSideImageContainer {
    flex: 0 0 calc(50% - 0.8rem);
}
.oasisPeaceContainer > .leftSideTextContainer {
    
}
.oasisPeaceContainer > .rightSideImageContainer {
    display: flex;
}
.oasisPeaceContainer > .rightSideImageContainer > img {
    width: 100%;
    object-fit: cover; 
    padding: 10px;
    box-shadow:1px 12px 30px 1px #a4bcc3;
   transform: scale(1.0,1.1);
   
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ testimonials ~~~~~~~~~~~~~~~~~~~~~~~~~ */
.testimonialsWrapper {
    margin-top: 8rem;
    margin-bottom: 5rem;
   
}
.testimonialsCardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
    background-color: #5c8f89;
    height: 300px;
}
.testimonialsCardContainer{
    flex: 1 0 calc(33.33% - 0.7rem);
    height: 280px;
    width: 850px;
    row-gap: 1rem;
    background-color: #c1dbd8;
    padding: 5px;
    
}




/* ~~~~~~~~~~~~~~~~~~~~~~~~ Courses ~~~~~~~~~~~~~~~~~~~~~~~~~ */
.coursesWrapper:hover {
    margin-top: 10  rem;
    transition: 1s;
    opacity: 1;
   padding: 4rem;
}
.coursesCardWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4%;
    padding: 2rem;
}
.coursesCard {
    flex: 0 0 calc(25% - 10px);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    padding: 1rem;
    margin: 5px;
    border: 1px dotted #305555;
    border-radius: 16px;
    box-shadow:14px 15px 30px 11px #aae3db;
   transform: scale(1.0,1.1);
}

.coursesCard img {
    width: 100%;
    height: 15rem;
    
}
/* ~~~~~~~~~~~~~~~~~~~~~~~~ Daily Sustenance ~~~~~~~~~~~~~~~~~~~~~~~~~ */
.dailySustenanceCardsWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 4rem;
    gap: 1rem;
    padding: 9rem;
}
.dailySustenanceCard {
    flex: 0 0 calc(33.33% - 0.7rem);
    margin-top: 2rem !important;
    margin-bottom: 50px;

}
.dailySustenanceCard img {
    width: 100%;
    height: 25rem;
}

/* ~~~~~~~~~~~~~~~~~~~~~~~~ Media Query ~~~~~~~~~~~~~~~~~~~~~~~~~ */
@media screen and (max-width: 800px) {
    .oasisPeaceContainer {
        flex-direction: column;
    }
    .testimonialsCardContainer {
        flex: 0 0 calc(50% - 0.5rem);
    }
    .dailySustenanceCard {
        flex: 0 0 calc(50% - 0.5rem);
    }
    .coursesCard {
        flex: 0 0 calc(33% - 10px);
    }
}
@media screen and (max-width: 600px) {
    .coursesCard {
        flex: 0 0 calc(50% - 10px);
    }
    .testimonialsCardContainer {
        flex: 0 0 calc(100%);
    }
}