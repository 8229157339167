.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.content-container {
  width: min(110rem, 90%);
  margin: auto;
}

.App-preheader {
  background-color: #274e61;
  width: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-nevbar {
  width: 100%;
}

.App-top {
  position: fixed;
}

.App-home {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(35, 7, 7);
}
.App-img {
  width: 50%;
  display: flex;
  margin-left: 70px;
}

.App-bliss {
  padding: 10px;
  border-radius: 20px;
  margin: 1px;
}

.App-sus {
  padding: 10px;
  height: 450px;
}

.App-footer {
  background-color: #2f4f5c;
  /* height: 300px; */
  width: 100%;
  /* margin-top: 150px; */
  margin-top: 5rem;
  padding: 2rem 1rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.App-table {
  padding: 60px;
}

.App-event{
  margin: 200px auto;
}

.App-event-content{
  box-shadow: 10px 10px 10px -10px rgba(0,0,0,0.3),
  inset 10px 10px 10px -10px
  rgba(0,0,0,0.5);
  padding: 10px;
  cursor: pointer;
  background: #fff;
  margin-top: -70px;
  position: absolute;
  margin-left: 70px;
  width: 600px;
  
}

.App-event-content h4{
  font-weight: bold;
  font-style: italic;
  font-size: 20px;
  margin-bottom: 5px;
}

.App-event-content span{
  font-style: italic;
  font-size: 15px;
}
.App-event-content a {
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 10px;;
}
.App-event img{
  transition: 0.6s;
  
}

.App-event img:hover{
  transform: scale(1.1);
  cursor: pointer;
}

.App-course {
  padding: 20px;
  border-radius: 30px;
}
.App-course-sub {
  margin-left: 80px;
  
}


.App-contactus {
  margin-left: 300px;
  width: 600px;
  padding: 35px;
}

.App-form-button {
  align-items: center;
  margin-top: 30px;
}

.App-gallery {
  padding: 7px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: sans-serif;
}
input {
  display: none;
}

.App-container {
  width: 100%;
  text-align: center;
}

.App-gallery-h1 {
  font-weight: normal;
  font-size: 35px;
  position: relative;
  margin: 40px 0;
}

.App-gallery-h1::before {
  content: "";
  position: absolute;
  width: 100px;
  height: 3px;
  background-color: crimson;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
  animation: animate 4s linear infinite;
}

.App-top-content {
  background-color: rgb(243, 243, 243);
  width: 90%;
  margin: 0 auto 20px auto;
  height: 60px;
  display: flex;
  align-items: center;
  border-radius: 5px;
  box-shadow: 3px 3px 5px rgb(238, 236, 236);
}
.App-gallery-h3 {
  height: 100%;
  background-color: rgb(57, 120, 126);
  line-height: 60px;
  padding: 0 50px;
  color: white;
}
label {
  display: inline-block;
  height: 100%;
  margin: 0 20px;
  line-height: 60px;
  font-size: 18px;
  color: rgb(179, 174, 174);
  cursor: pointer;
  transition: color 0.5s;
}
label:hover {
  color: black;
}
.App-photo-gallery {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
.App-pic {
  position: relative;
  height: 230px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px lightgray;
  cursor: pointer;
}
.App-pic img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  transition: 0.5s;
}
.App-pic::before {

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 22px;
  font-weight: bold;
  width: 100%;
  margin-top: -100px;
  opacity: 0;
  transition: 0.3s;
  transition-delay: 0.2s;
  z-index: 1;
}
.App-pic::after {
  content: "";
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  border-radius: 10px;
  height: 100%;
 
}
.App-pic:hover::after {
  height: 100%;
}
.App-pic:hover::before {
  margin-top: 0;
  opacity: 1;
}
#check1:checked ~ .App-container .App-photo-gallery .App-pic {
  opacity: 1;
  transform: scale(1);
  position: relative;
  transition: 0.5s;
}
#check2:checked ~ .App-container .App-photo-gallery .App-family {
  opacity: 1;
  transform: scale(1);
  position: relative;
}
#check2:checked ~ .App-container .App-photo-gallery .App-child,
#check2:checked ~ .App-container .App-photo-gallery .App-place {
  opacity: 0;
  transform: scale(0);
  position: absolute;
  transition: 0s;
}

#check3:checked ~ .App-container .App-photo-gallery .App-child {
  opacity: 1;
  transform: scale(1);
  position: relative;
}
#check3:checked ~ .App-container .App-photo-gallery .App-family,
#check3:checked ~ .App-container .App-photo-gallery .App-place {
  opacity: 0;
  transform: scale(0);
  position: absolute;
  transition: 0s;
}
#check4:checked ~ .App-container .App-photo-gallery .App-place {
  opacity: 1;
  transform: scale(1);
  position: relative;
}
#check4:checked ~ .App-container .App-photo-gallery .App-family,
#check4:checked ~ .App-container .App-photo-gallery .App-child {
  opacity: 0;
  transform: scale(0);
  position: absolute;
  transition: 0s;
}

.App-map-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin-top: 50px;
}

.App-map-responsive iframe {
  left: 0;
  top: 0;
  border: 0;
  height: 90%;
  width: 100%;
  position: absolute;
  padding: 15px;
}

@keyframes animate {
  0% {
    width: 100px;
  }
  50% {
    width: 200px;
  }
  100% {
    width: 100px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.App-MainContainer-h1 {
  transform: translate(-50%, -50%);
}

@media (min-width: 200px) and (max-width: 600px) {
  .collapse {
    /* display: none !important; */
  }
}

.App-gallery {
  margin: 5px;
  border: 1px dotted #29a1a1;
  float: left;
  width: 260px;
  border-radius: 16px;

}

.App-gallery:hover {
  border: 1px dotted  #29a1a1;
text-align: center;
}

.App-gallery img {
  width: 100%;
  height: 250px;
  border-radius: 20px;
}

.App-daily {
  border: 1px solid rgb(34, 42, 42);
  text-align: center;
  display: inline-block;
  padding: 10px;
  border-radius: 15px;
  margin-top: 50px;
  cursor:pointer;
  box-shadow: 1px 12px 30px 0px rgb(161, 62, 62);
  transform: scale(1.0,0.9);
  transition: all 800ms;
}

.App-daily img:hover{
 padding: 5px;
  border-radius: 30px;
  transition: all 900ms;
  opacity: 0.4;
}
.App-desc {
  padding: 15px;
  text-align: center;
  color:#2f4f5c;
}



.App-form-main {
  padding: 5%;
  text-align: center;
}

.App-form-input {
  padding: 1%;
  width: 50%;
  margin: 1% 2%;
}

.App-form-button {
  font-weight: bold;
  background: red;
  border: none;
  color: white;
  padding: 1%;
}

.body:hover{
  background: linear-gradient(-100deg,rgb(219, 246, 239) 50%,rgb(223, 236, 246) 50%);
  height: 250px;
  font-family: poppins;
  margin-top: 3%;
  margin-bottom: 7%;
  opacity: 0.7;
}