.galleryWrapper {
    margin-top: 4rem;
}

.appPhotoGalleryWrapper {
    margin-top: 1.5rem;
    width: 100% !important;
}



